import React, { Fragment } from 'react';
import { compose } from 'recompose';

import Layout from '../App/components/layout';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';


import UpdateProfileBase from '../components/UpdateProfile';

import SEO from "../App/components/seo"


const AccountPageBase = () => (
  <AuthUserContext.Consumer>
    {authUser =>
        authUser ? (
          <Fragment>
            <div class="wrapper maxWidth"><h1>Profile</h1>
                <UpdateProfileBase user={authUser}/>
            </div>

          </Fragment>
        ): <></>
  }
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPageBase);

export default () =>(
  <Layout>
    <SEO title="" />
    <AccountPage/>
  </Layout>
);

/*
    <AuthUserContext.Consumer>
      {authUser => (
        <div class="wrapper">
          <h1>Profile</h1>

          {authUser.username ? 'Name: '+ authUser.username : ''}
          {authUser.email}
          {authUser.phone ? 'Phone: '+authUser.phone : '' }
          {authUser.companyName}
          {authUser.companyAddress}


          <PasswordChangeForm />
        </div>

      )}
          </AuthUserContext.Consumer>
  */
