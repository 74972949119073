import React, { Component } from 'react';
import { Link } from 'gatsby';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'


import "../../../cms/web/dist/styles/procurement.css";
/*
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  newEmail: '',
  newCompany: '',
  newCompanyAddress: '',
  error: null,
  errorEmail: null,
  name: '',
  phone: '',

};*/

class UpdateProfileForm extends Component {
  constructor(props) {
    super(props);
    this.user = this.props.user;
    this.state = {
      newCompany: this.user.companyName,
      newCompanyAddress: this.user.companyAddress,
      newCompanyAddress2: this.user.companyAddress2,
      newCompanyCity: this.user.companyCity,
      newCompanyProvince: this.user.companyProvince,
      newCompanyPostal: this.user.companyPostal,
      name: this.user.username,
      phone: this.user.phone,
      email: this.user.email,
      validPhone: true,
    };


  }

  componentDidMount = () =>{


  }



  onSubmit = event => {
    const { newCompany, newCompanyAddress, newCompanyAddress2, newCompanyCity, newCompanyProvince, newCompanyPostal, name, phone } = this.state;
    this.props.firebase.doUpdateCompany(newCompanyAddress);
    this.props.firebase.doUpdateCompanyAddress2(newCompanyAddress2);
    this.props.firebase.doUpdateCompanyCity(newCompanyCity);
    this.props.firebase.doUpdateCompanyProvince(newCompanyProvince);
    this.props.firebase.doUpdateCompanyPostal(newCompanyPostal);
    this.props.firebase.doUpdateCompany(newCompany);
    this.props.firebase.doUpdatePhone(phone);
    this.props.firebase.doUpdateName(name);

    var theDiv = document.getElementById("message");
    var content = document.createTextNode("Profile successfully updated");
    theDiv.appendChild(content);

    event.preventDefault();
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangePhoneNumber = event => {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    var phoneHyphon = /^\d{3}-\d{3}-\d{4}$/
    this.setState({ [event.target.name]: event.target.value });
    if(phoneno.test(event.target.value) || phoneHyphon.test(event.target.value) ){

      this.setState({validPhone: true})
    }
    else{
      this.setState({validPhone: false})
    }

  }

  render() {
    const { newCompany, newCompanyAddress, newCompanyAddress2, newCompanyCity, newCompanyProvince, newCompanyPostal, name, phone, email } = this.state;


    return (
      <div>
      <h2 className="sectionHeading">Account Information</h2>
      <p><strong>Email Address:</strong> {email} </p>
      <p><Link className="underlineLink" to={ROUTES.UPDATE_EMAIL}>Update Email <FontAwesomeIcon className="reg" icon={faArrowRight} /></Link></p>

      <p className="passwordy"><strong>Password</strong></p>
      <Link className="underlineLink" to={ROUTES.UPDATE_PASSWORD}>Update Password <FontAwesomeIcon className="reg" icon={faArrowRight} /></Link>

      <hr/>
      <h2 className="sectionHeading">Profile Info</h2>
        <form id="fullWidthForm" className="validate-password" onSubmit={this.onSubmit}>
        <div>
            <label htmlFor="name">Name</label>
            <input
            name="name"
            value={name}
            type="text"
            onChange={this.onChange}
            placeholder="Name"
            />
          </div>

         <div>
            <label htmlFor="newCompany">Company</label>
            <input
            name="newCompany"
            value={newCompany}
            type="text"
            onChange={this.onChange}
            placeholder="Add Company Name..."
            />
        </div>

         <div>

            <label htmlFor="newCompanyAddress">Company Address Line 1</label>
            <input
            name="newCompanyAddress"
            value={newCompanyAddress}
            type="text"
            onChange={this.onChange}
            placeholder="Add Company Address..."
            />
          </div>

          <div>

             <label htmlFor="newCompanyAddress2">Company Address Line 2</label>
             <input
             name="newCompanyAddress2"
             value={newCompanyAddress2}
             type="text"
             onChange={this.onChange}
             placeholder="Add Company Address Line 2..."
             />
           </div>
           <div>

              <label htmlFor="newCompanyCity">City</label>
              <input
              name="newCompanyCity"
              value={newCompanyCity}
              type="text"
              onChange={this.onChange}
              placeholder="Add City..."
              />
          </div>
          <div>
             <label htmlFor="newCompanyProvince">Province</label>
             <input
             name="newCompanyProvince"
             value={newCompanyProvince}
             type="text"
             onChange={this.onChange}
             placeholder="Add Province..."
             />
          </div>
          <div>
             <label htmlFor="newCompanyPostal">Postal</label>
             <input
             name="newCompanyPostal"
             value={newCompanyPostal}
             type="text"
             onChange={this.onChange}
             placeholder="Add Postal..."
             />
          </div>
          <div>

          </div>
          <div>

                <label htmlFor="phone">Phone Number</label>
                <input
                name="phone"
                value={phone}
                type="text"
                onChange={this.onChangePhoneNumber}
                placeholder="Add Phone Number..."
                />
          </div>

          <div>
            <button type="submit" disabled={!this.state.validPhone}>
                  Update Information
            </button>
            <p id="message"></p>
          </div>
        </form>

      </div>
    );
  }
}

export default withFirebase(UpdateProfileForm);
